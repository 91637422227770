export const dateRangeOptions = [
  { value: 1, text: "Giornaliero" },
  { value: 2, text: "Settimanale" },
  { value: 3, text: "Mensile" },
];

export const frequencyOptions = {
  DAILY: {
    value: 1,
    label: "Daily",
  },
  WEEKLY: {
    value: 2,
    label: "Weekly",
  },
  MONTHLY: {
    value: 3,
    label: "Monthly",
  },
};

export const userRoles = {
  SUPERADMIN: "super-admin",
  ADMIN: "admin",
  FULL: "full",
  MARKETING: "marketing",
  SALES: "sales",
  PRODUCTION: "production",
  ACCOUNTING: "accounting",
  CLIENT: "client",
  STATS: "stats",
};

export const leadStatuses = {
  PENDING: "Pending",
  SENT: "Sent",
  REJECTED: "Rejected",
  UNKNOWN: "Unknown error",
  TEST: "Test",
  DUPLICATE: "Duplicate",
  TOBEQUALIFIED: "To Be Qualified",
  QUALIFICATIONPENDING: "Qualification Pending",
  QUALIFICATIONREJECTED: "Qualification Rejected",
};

export const fbPlanningStatuses = {
  DRAFT: {
    value: 0,
    label: "Draft",
  },
  IN_PROGRESS: {
    value: 1,
    label: "In progress",
  },
  COMPLETE: {
    value: 2,
    label: "Complete",
  },
  QUEUED: {
    value: 3,
    label: "Queued",
  },
};

export const crons = [
  { label: "Every 5 minutes", value: "*/5 * * * *" },
  { label: "Every 10 minutes", value: "*/10 * * * *" },
  { label: "Every 15 minutes", value: "*/15 * * * *" },
  { label: "Every 30 minutes", value: "*/30 * * * *" },
  { label: "Every 45 minutes", value: "*/45 * * * *" },
  { label: "Every hour", value: "0 * * * *" },
  { label: "At 18.30", value: "30 18 * * *" },
  { label: "At 19.00", value: "0 19 * * *" },
  { label: "At 19.30", value: "30 19 * * *" },
  { label: "At 20.00", value: "0 20 * * *" },
  { label: "At 14.30 on SAT", value: "30 14 * * 6" },
  { label: "At 15.30 on SAT", value: "30 15 * * 6" },
];

export const facebookOperationTypes = [
  {
    value: 1,
    label: "Change status",
  },
  {
    value: 2,
    label: "Change budget",
  },
  {
    value: 3,
    label: "Old duplicate campaign",
  },
  {
    value: 4,
    label: "Duplicate campaign",
  },
  {
    value: 5,
    label: "Plan campaign",
  },
  {
    value: 6,
    label: "Delete Fb object",
  },
  {
    value: 7,
    label: "Update Fb Object",
  },
  {
    value: 8,
    label: "Plan Fb geo campaign",
  },
  {
    value: 9,
    label: "Create geo adset",
  },
];

export const facebookAdAccountStatuses = {
  ACTIVE: {
    value: 1,
    label: "Active",
  },
  DISABLED: {
    value: 2,
    label: "Disabled",
  },
  PAYMENT_WARNING: {
    value: 3,
    label: "Payment warning",
  },
  PENDING_RISK: {
    value: 7,
    label: "Pending risk review",
  },
  PENDING_SETTLEMENT: {
    value: 8,
    label: "Pending settlement",
  },
  IN_GRACE: {
    value: 9,
    label: "In grace period",
  },
  PENDING_CLOSURE: {
    value: 100,
    label: "Pending closure",
  },
  CLOSE: {
    value: 101,
    label: "Closed",
  },
  ANY_ACTIVE: {
    value: 201,
    label: "Any active",
  },
  ANY_CLOSED: {
    value: 202,
    label: "Any closed",
  },
};

export const wikiCategoriesOptions = [
  { value: 0, text: "Brain" },
  { value: 1, text: "Vinta" },
  { value: 2, text: "Adverstory" },
  { value: 3, text: "Procedure" },
  { value: 4, text: "A/B Test" },
  { value: 5, text: "Export" },
  { value: 6, text: "Test Asset" },
  { value: 7, text: "Legal" },
];

export const leadStatsAdditionalColumns = [
  {
    name: "lead_campaign_id",
    label: "Campagna",
    query: "lead_campaign_id",
  },
  {
    name: "client_id",
    label: "Cliente",
    query: "client_id",
  },
  {
    name: "ab_test_id",
    label: "A/B Test",
    query: "ab_test_id",
  },
  {
    name: "smart_link_id",
    label: "Smart link",
    query: "smart_link_id",
  },
  {
    name: "flamel_id",
    label: "Flamel planning",
    query: "flamel_id",
  },
  {
    name: "copy_cgid",
    label: "Copy creative group",
    query: "copy_cgid",
  },
  {
    name: "media_cgid",
    label: "Media creative group",
    query: "media_cgid",
  },
  {
    name: "funnel_id",
    label: "Funnel",
    query: "funnel_id",
  },
  {
    name: "utm_source",
    label: "UTM Source",
    query: "utm_source",
  },
  {
    name: "utm_medium",
    label: "UTM Medium",
    query: "utm_medium",
  },
  {
    name: "utm_campaign",
    label: "UTM Campaign",
    query: "utm_campaign",
  },
  {
    name: "utm_content",
    label: "UTM Content",
    query: "utm_content",
  },
  {
    name: "utm_term",
    label: "UTM Term",
    query: "utm_term",
  },
  {
    name: "page_url",
    label: "Page URL",
    query: "page_url",
  },
  {
    name: "page_type",
    label: "Page Type",
    query: "page_type",
  },
  {
    name: "page_name",
    label: "Page Name",
    query: "page_name",
  },
  {
    name: "customer_source",
    label: "Customer Source",
    query: "customer_source",
  },
  {
    name: "tag",
    label: "Tag",
    query: "tag",
  },
  {
    name: "page_categories",
    label: "Categories",
    query: "page_categories",
  },
  {
    name: "fb_ad_account_id",
    label: "Facebook AD Account",
    query: "fb_ad_account_id",
  },
  {
    name: "fb_ctr",
    label: "Facebook CTR",
    query: "fb_ctr",
  },
  {
    name: "fb_cpc",
    label: "Facebook CPC",
    query: "fb_cpc",
  },
  {
    name: "fb_cpm",
    label: "Facebook CPM",
    query: "fb_cpm",
  },
  {
    name: "fb_cpp",
    label: "Facebook CPP",
    query: "fb_cpp",
  },
  {
    name: "fb_frequency",
    label: "Facebook Frequency",
    query: "fb_frequency",
  },
  {
    name: "fb_reach",
    label: "Facebook Reach",
    query: "fb_reach",
  },
  {
    name: "fb_impressions",
    label: "Facebook Impressions",
    query: "fb_impressions",
  },
  {
    name: "fb_clicks",
    label: "Facebook Clicks",
    query: "fb_clicks",
  },
  {
    name: "fb_ad_id",
    label: "Facebook Ad ID",
    query: "fb_ad_id",
  },
  {
    name: "fb_ad_name",
    label: "Facebook Ad name",
    query: "fb_ad_name",
  },
  {
    name: "fb_adset_id",
    label: "Facebook Adset ID",
    query: "fb_adset_id",
  },
  {
    name: "fb_adset_name",
    label: "Facebook Adset name",
    query: "fb_adset_name",
  },
  {
    name: "fb_campaign_id",
    label: "Facebook Campaign ID",
    query: "fb_campaign_id",
  },
  {
    name: "fb_campaign_name",
    label: "Facebook Campaign Name",
    query: "fb_campaign_name",
  },
  {
    name: "fb_site_source_name",
    label: "Facebook Site source name",
    query: "fb_site_source_name",
  },
  {
    name: "tt_ad_name",
    label: "TikTok Ad name",
    query: "tt_ad_name",
  },
];

export const merlinRulesPlaceholderVariables = [
  {
    type: "object",
    value: "{target}",
    label: "Target",
  },
  {
    type: "object",
    value: "{current_time}",
    label: "Current time",
  },
  {
    type: "object",
    value: "{objects}",
    label: "Objects number",
  },
  {
    type: "object",
    value: "{object_aggregate_spend}",
    label: "Object aggregate spend",
  },
  {
    type: "object",
    value: "{object_aggregate_cpl}",
    label: "Object aggregate CPL",
  },
  {
    type: "object",
    value: "{object_aggregate_lead_count}",
    label: "Object aggregate lead count",
  },
  {
    type: "global",
    value: "{global_click_count}",
    label: "Global click count",
  },
  {
    type: "global",
    value: "{global_lead_count}",
    label: "Global lead count",
  },
  {
    type: "global",
    value: "{global_lead_value}",
    label: "Global lead value",
  },
  {
    type: "global",
    value: "{global_cpl}",
    label: "Global CPL",
  },
  {
    type: "global",
    value: "{global_coverage}",
    label: "Global coverage",
  },
  {
    type: "global",
    value: "{global_margin}",
    label: "Global margin",
  },
  {
    type: "excluded",
    value: "{excluded_objects}",
    label: "Excluded objects number",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_spend}",
    label: "Excluded object aggregate spend",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_impressions}",
    label: "Excluded object aggregate impressions",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_frequency}",
    label: "Excluded object aggregate frequency",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_click_count}",
    label: "Excluded object aggregate click count",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_lead_count}",
    label: "Excluded object aggregate lead count",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_lead_value}",
    label: "Excluded object aggregate lead value",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_active_budget}",
    label: "Excluded object aggregate active budget",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_margin}",
    label: "Excluded object aggregate margin",
  },
  {
    type: "excluded",
    value: "{excluded_object_aggregate_cpl}",
    label: "Excluded object aggregate CPL",
  },
];

export const merlinRulesQueryFields = [
  {
    name: "is_scaled",
    label: "Is scaled",
    valueEditorType: "text",
    inputType: "text",
  },
  {
    name: "last_scaled_lead_count",
    label: "Last scaled lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "last_scaled_lead_diff",
    label: "Last scaled lead diff",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_active_budget",
    label: "Object active budget",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_budget",
    label: "Object budget",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_click_count",
    label: "Object click count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_coverage",
    label: "Object coverage",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_cpc",
    label: "Object CPC",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_cpl",
    label: "Object CPL",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_cpm",
    label: "Object CPM",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_cpa",
    label: "Object CPA",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_ctr",
    label: "Object CTR",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_frequency",
    label: "Object frequency",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_lead_count",
    label: "Object lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_lead_value",
    label: "Object lead value",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_margin",
    label: "Object margin",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_spend",
    label: "Object spend",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_sent_coverage",
    label: "Object sent coverage",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_sent_cpl",
    label: "Object sent CPL",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_sent_lead_count",
    label: "Object sent lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_sent_lead_value",
    label: "Object sent lead value",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_sent_margin",
    label: "Object sent margin",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_lead_count",
    label: "Object aggregate lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_sent_lead_count",
    label: "Object aggregate sent lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_lead_value",
    label: "Object aggregate lead value",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_sent_lead_value",
    label: "Object aggregate sent lead value",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_spend",
    label: "Object aggregate spend",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_cpl",
    label: "Object aggregate CPL",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_sent_cpl",
    label: "Object aggregate sent CPL",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_margin",
    label: "Object aggregate margin",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "object_aggregate_sent_margin",
    label: "Object aggregate sent margin",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "campaign_lead_count",
    label: "Brain campaign lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "weekly_campaign_lead_count",
    label: "Brain WEEKLY campaign lead count",
    valueEditorType: "number",
    inputType: "number",
  },
  {
    name: "monthly_campaign_lead_count",
    label: "Brain MONTHLY campaign lead count",
    valueEditorType: "number",
    inputType: "number",
  },
];

export const leadStatsPartnerStatuses = [
  {
    name: "registrato",
    label: "Registrato",
    query: "registrato",
  },
  {
    name: "prenotazione",
    label: "Prenotazione",
    query: "prenotazione",
  },
  {
    name: "archiviato",
    label: "Archiviato",
    query: "archiviato",
  },
  {
    name: "archiviato_irreperibile",
    label: "Archiviato/Irreperibile",
    query: "archiviato_irreperibile",
  },
  {
    name: "cliente",
    label: "Cliente",
    query: "cliente",
  },
  {
    name: "irreperibile",
    label: "Irreperibile",
    query: "irreperibile",
  },
  {
    name: "appuntamento",
    label: "Appuntamento",
    query: "appuntamento",
  },
  {
    name: "ni",
    label: "NI",
    query: "ni",
  },
  {
    name: "nr",
    label: "NR",
    query: "nr",
  },
  {
    name: "ne",
    label: "NE",
    query: "ne",
  },
  {
    name: "ok",
    label: "OK",
    query: "ok",
  },
  {
    name: "ko",
    label: "KO",
    query: "ko",
  },
  {
    name: "working",
    label: "WORKING",
    query: "working",
  },
];

export const facebookCampaignStatuses = [
  {
    name: "ACTIVE",
    label: "Active",
  },
  {
    name: "PAUSED",
    label: "Paused",
  },
];

export const TikTokCampaignStatuses = [
  {
    name: "ENABLE",
    label: "Enabled",
  },
  {
    name: "DISABLE",
    label: "Disabled",
  },
];

export const facebookPublisherPlatforms = [
  {
    name: "facebook",
    label: "Facebook",
    value: "facebook",
  },
  {
    name: "instagram",
    label: "Instagram",
    value: "instagram",
  },
  {
    name: "messenger",
    label: "Messenger",
    value: "messenger",
  },
  {
    name: "audience_network",
    label: "Audience Network",
    value: "audience_network",
  },
];

export const facebookDevicePlatforms = [
  {
    name: "desktop",
    label: "Desktop",
    value: "desktop",
  },
  {
    name: "mobile",
    label: "Mobile",
    value: "mobile",
  },
];

export const facebookPositions = [
  {
    name: "feed",
    label: "Feed",
    value: "feed",
  },
  {
    name: "right_hand_column",
    label: "Right hand column",
    value: "right_hand_column",
  },
  {
    name: "instant_article",
    label: "Instant article",
    value: "instant_article",
  },
  {
    name: "marketplace",
    label: "Marketplace",
    value: "marketplace",
  },
  {
    name: "video_feeds",
    label: "Video feeds",
    value: "video_feeds",
  },
  {
    name: "story",
    label: "Story",
    value: "story",
  },
  {
    name: "search",
    label: "Search",
    value: "search",
  },
  {
    name: "instream_video",
    label: "Instream video",
    value: "instream_video",
  },
];

export const facebookSpecialAdCategories = [
  {
    name: "housing",
    label: "Housing",
    value: "HOUSING",
  },
  {
    name: "credit",
    label: "Credit",
    value: "CREDIT",
  },
  {
    name: "employment",
    label: "Employment",
    value: "EMPLOYMENT",
  },
  {
    name: "issues_elections_politics",
    label: "Issues Elections Politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
];

export const instagramPositions = [
  {
    name: "stream",
    label: "Stream",
    value: "stream",
  },
  {
    name: "story",
    label: "Story",
    value: "story",
  },
  {
    name: "explore",
    label: "Explore",
    value: "explore",
  },
];

export const audienceNetworkPositions = [
  {
    name: "classic",
    label: "Classic",
    value: "classic",
  },
  {
    name: "instream_video",
    label: "Instream video",
    value: "instream_video",
  },
  {
    name: "rewarded_video",
    label: "Rewarded video",
    value: "rewarded_video",
  },
];

export const merlinRuleActionType = [
  {
    label: "Avada kedavra ☠️",
    name: "Turn off",
    description: "Turn off all the matching objects",
    value: 0,
  },
];

export const merlinRuleObjectType = [
  {
    label: "Campaign",
    value: 0,
  },
  /*  {
    label: "Adset",
    value: 1,
  },
  {
    label: "Ad",
    value: 2,
  }, */
];

export const merlinRuleTargetType = {
  /* GLOBAL: {
    label: "Global",
    value: 0,
  },
  TRAFFIC_SOURCE: {
    label: "Traffic source",
    value: 1,
  }, */
  AD_ACCOUNT: {
    label: "Ad Account",
    value: 2,
  },
};

export const merlinRuleStatuses = {
  INACTIVE: "Inactive",
  ACTIVE: "Active",
};

export const merlinRulePriorityStatuses = {
  HIGH: "High",
  NORMAL: "Normal",
  LOW: "Low",
};

export const merlinRuleLockStatuses = {
  LOCKED: "Active",
  UNLOCKED: "Inactive",
};

export const leadStatsSources = [
  {
    name: "facebook",
    label: "Facebook",
    query: "Facebook",
  },
  {
    name: "google",
    label: "Google",
    query: "Google",
  },
  {
    name: "tiktok",
    label: "TikTok",
    query: "TikTok",
  },
  {
    name: "email",
    label: "Email",
    query: "Email",
  },
  {
    name: "outbrain",
    label: "Outbrain",
    query: "Outbrain",
  },
  {
    name: "taboola",
    label: "Taboola",
    query: "Taboola",
  },
];

export const hiddenLeadCampaignParams = [
  "_lead_campaign_id",
  "_client_id",
  "_user_agent",
  "_page_url",
];

export const campaignTestGetParams = [
  "fb_ad_id",
  "fb_ad_name",
  "fb_campaign_id",
  "fb_campaign_name",
  "fb_adset_id",
  "fb_adset_name",
  "fb_site_source_name",
  "utm_campaign",
  "utm_medium",
  "utm_term",
];

export const linkTypes = [
  {
    value: 0,
    text: "Adverstory",
    model: "Adverstory",
    frontendUrl: "https://stories.spinup.media/s",
    editPath: "/adverstories",
    apiPath: "/api/adverstory",
    apiObject: "adverstory",
    bodyCol: "body",
    settingsCol: "settings",
  },
  {
    value: 1,
    text: "Storybot",
    model: "Adverstory",
    frontendUrl: "https://stories.spinup.media/s",
    editPath: "/adverstories",
    apiPath: "/api/adverstory",
    apiObject: "adverstory",
    bodyCol: "body",
    settingsCol: "settings",
  },
  {
    value: 2,
    text: "Videoads",
    model: "Adverstory",
    frontendUrl: "https://stories.spinup.media/s",
    editPath: "/adverstories",
    apiPath: "/api/adverstory",
    apiObject: "adverstory",
    bodyCol: "body",
    settingsCol: "settings",
  },
  {
    value: 3,
    text: "Chatbot",
    model: "Chatbot",
    frontendUrl: "https://chats.spinup.media/c",
    editPath: "/chatbots",
    apiPath: "/api/chatbots",
    apiObject: "chatbot",
    bodyCol: "blocks",
    settingsCol: "custom_settings",
  },
  {
    value: 4,
    text: "Adbot",
    model: "Chatbot",
    frontendUrl: "https://chats.spinup.media/c",
    editPath: "/chatbots",
    apiPath: "/api/chatbots",
    apiObject: "chatbot",
    bodyCol: "blocks",
    settingsCol: "custom_settings",
  },
  {
    value: 5,
    text: "Bannerbot",
    model: "Chatbot",
    frontendUrl: "https://chats.spinup.media/c",
    editPath: "/chatbots",
    apiPath: "/api/chatbots",
    apiObject: "chatbot",
    bodyCol: "blocks",
    settingsCol: "custom_settings",
  },
  {
    value: 6,
    text: "Wizard",
    model: "Wizard",
    frontendUrl: "https://wizards.spinup.media/w",
    editPath: "/wizards",
    apiPath: "/api/wizard",
    apiObject: "wizard",
    bodyCol: "body",
    settingsCol: "settings",
  },
  {
    value: 7,
    text: "Feed",
    model: "Feed",
    frontendUrl: "https://feeds.spinup.media/f",
    editPath: "/feeds",
    apiPath: "/api/feeds",
    apiObject: "feed",
    bodyCol: "body",
    settingsCol: "settings",
  },
];

export const creativeTypeOptions = [
  {
    value: 0,
    label: "Image",
    parent: "media",
  },
  {
    value: 1,
    label: "Video",
    parent: "media",
  },
  {
    value: 2,
    label: "Body",
    parent: "copy",
  },
  {
    value: 3,
    label: "Title",
    parent: "copy",
  },
  {
    value: 4,
    label: "Description",
    parent: "copy",
  },
  {
    value: 5,
    label: "Internal asset",
    parent: "copy",
  },
  {
    value: 6,
    label: "External asset",
    parent: "copy",
  },
];

export const creativeGroupTypeOptions = {
  0: {
    label: "Media",
    type: "media",
  },
  1: {
    label: "Copy",
    type: "copy",
  },
};

export const creativeGroupStatusOptions = {
  0: {
    value: 0,
    label: "To do",
    category: "task",
    order: 1,
    color: "subdued",
  },
  1: {
    value: 1,
    label: "Work in progress",
    category: "task",
    order: 2,
    color: "primary",
  },
  2: {
    value: 2,
    label: "Pending review",
    category: "standard",
    order: 5,
    color: "warning",
    icon: "training",
    action: "pending-review",
  },
  3: {
    value: 3,
    label: "Approved",
    category: "standard",
    order: 6,
    color: "secondary",
    icon: "cheer",
    action: "approve",
  },
  4: {
    value: 4,
    label: "Draft",
    category: "standard",
    order: 3,
    color: "accent",
  },
  5: {
    value: 5,
    label: "Changes requested",
    category: "standard",
    order: 7,
    color: "danger",
  },
  6: {
    value: 6,
    label: "Ready",
    category: "standard",
    order: 4,
    color: "ghost",
    icon: "videoPlayer",
    action: "ready",
  },
};

export const magnumBiLeadStatsPartnerStatuses = [
  {
    name: "registrato",
    label: "Registrato",
    query: "registrato_lead_count",
  },
  {
    name: "archiviato",
    label: "Archiviato",
    query: "archiviato_lead_count",
  },
  {
    name: "archiviato_irreperibile",
    label: "Archiviato/Irreperibile",
    query: "archiviato_irreperibile_lead_count",
  },
  {
    name: "cliente",
    label: "Cliente",
    query: "cliente_lead_count",
  },
  {
    name: "irreperibile",
    label: "Irreperibile",
    query: "irreperibile_lead_count",
  },
  {
    name: "appuntamento",
    label: "Appuntamento",
    query: "appuntamento_lead_count",
  },
  {
    name: "ok",
    label: "OK",
    query: "ok_lead_count",
  },
  {
    name: "ko",
    label: "KO",
    query: "ko_lead_count",
  },
];

export const magnumBiMetrics = [
  {
    query: "click_count",
    name: "click_count",
    label: "Clicks",
  },
  {
    query: "lead_count",
    name: "lead_count",
    label: "Leads",
  },
  {
    query: "lead_value",
    name: "lead_value",
    label: "Lead value",
  },
  {
    query: "spend",
    name: "spend",
    label: "Spend",
  },
  {
    query: "conversion_rate",
    name: "conversion_rate",
    label: "Conversion rate",
  },
  {
    query: "cpl",
    name: "cpl",
    label: "CPL",
  },
  {
    query: "roi",
    name: "roi",
    label: "Coverage",
  },
  {
    query: "margin",
    name: "margin",
    label: "Margin",
  },
];

export const alphabetString = "abcdefghijklmnopqrstuvwxyz";

export const slackWebhooks = [
  {
    label: "Brain notification",
    value: "SLACK_BRAIN_NOTIFICATIONS_HOOK",
  },
  {
    label: "Facebook notification",
    value: "SLACK_BRAIN_FB_NOTIFICATIONS_HOOK",
  },
  {
    label: "Merlin notification",
    value: "SLACK_BRAIN_MERLIN_NOTIFICATIONS_HOOK",
  },
  {
    label: "BI notification",
    value: "SLACK_BRAIN_BI_NOTIFICATIONS_HOOK",
  },
  {
    label: "Ready creative groups notification",
    value: "SLACK_BRAIN_READY_CREATIVES_NOTIFICATIONS_HOOK",
  },
  {
    label: "Approved creative groups notifications",
    value: "SLACK_BRAIN_CREATIVES_NOTIFICATIONS_HOOK",
  },
];

export const postbackEvents = {
  0: { label: "CLICK", color: "primary" },
  1: { label: "LEAD", color: "warning" },
  2: { label: "CONTRACT", color: "success" },
  3: { label: "SHEET", color: "danger" },
};

export const strengthPasswordRegEx =
  // eslint-disable-next-line no-useless-escape
  /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.\?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;

export const validationEmailRegEx =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationLinkRegEx =
  // eslint-disable-next-line no-useless-escape
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const validationDomainRegEx =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
